(function ($) {
    const Tools = {
        createSlug(text) {
            text = text.replace(/^\s+|\s+$/g, ""); // trim
            text = text.toLowerCase();

            // remove accents, swap ñ for n, etc
            var from = "ąåàáãäâęèéëêìíïîłòóöôùúüûńñśćçżź·/_,:;";
            var to =   "aaaaaaaeeeeeiiiiloooouuuunnscczz------";

            for (var i = 0, l = from.length; i < l; i++) {
                text = text.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
            }

            text = text
                .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
                .replace(/\s+/g, "-") // collapse whitespace and replace by -
                .replace(/-+/g, "-") // collapse dashes
                .replace(/^-+/, "") // trim - from start of text
                .replace(/-+$/, ""); // trim - from end of text

            return text;
        },
    }
    const Sliders = {
        owlArrow: `<svg class="arrow" viewBox="0 0 5.542 9.454" xmlns="http://www.w3.org/2000/svg"><path d="m1297.715 15.754a.74.74 0 0 1 -.571-.244.788.788 0 0 1 0-1.141l3.341-3.341-3.341-3.341a.807.807 0 0 1 1.141-1.141l3.912 3.912a.788.788 0 0 1 0 1.141l-3.912 3.912a.74.74 0 0 1 -.57.244z" fill="currentColor" transform="translate(-1296.9 -6.3)"/></svg>`,
        owlSpinningCirce: `<svg class="drawing-circle-svg" xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42"><circle class="drawing-circle" fill="none" stroke="currentColor" stroke-width="2" stroke-dasharray="0,100" cx="21" cy="21" r="20"/></svg>`,
        init() {
            const $home = $('.home-slider__slides');
            if ($home.length > 0) {

                $home.owlCarousel({
                    margin: 0,
                    autoplay: true,
                    smartSpeed: 550,
                    slideSpeed: 550,
                    autoplayTimeout: 4500,
                    nav: true,
                    dots: false,
                    items: 1,
                    loop: true,
                    mouseDrag: true,
                    touchDrag: true,
                    navText: [`<span>${this.owlArrow}</span>`, `<span>${this.owlSpinningCirce}${this.owlArrow}</span>`]
                });
                
                Sliders.setDrawing($home);
            }
            
            const $hero = $('.hero-section__slider');
            if ($hero.length > 0) {
                $hero.owlCarousel({
                    margin: 0,
                    smartSpeed: 550,
                    slideSpeed: 550,
                    nav: false,
                    dots: false,
                    items: 1,
                    loop: true,
                    mouseDrag: true,
                    touchDrag: true,
                });
                
                
                $hero.on('change.owl.carousel', function() {
		            $(".hero-section__slider__title, .hero-section__slider__lead, .hero-section__slider__link").removeClass("animate");
			           
		        });
		        
		        $hero.on('translate.owl.carousel', function() {
		           $(".hero-section__slider__title, .hero-section__slider__lead, .hero-section__slider__link").removeClass("animate");
			    });
                
                $hero.on('changed.owl.carousel', function() {
		            $(".hero-section__slider__title, .hero-section__slider__lead, .hero-section__slider__link").addClass("animate");
		        });
		        $hero.on('translated.owl.carousel', function() {
		            $(".hero-section__slider__title, .hero-section__slider__lead, .hero-section__slider__link").addClass("animate");
		        });
		        
		        $(document).on('click', ".slider-thumbs__item", function() {

					var $count = $(".slider-thumbs__items .slider-thumbs__item").length + 1;
					var $slide = $(this).data("thumb-id");
					$(".slider-thumbs__item").removeClass("active");
					$(this).addClass("active");
						if(parseInt($count) == parseInt($slide)) {
							var $s = $slide - 1;
						} else {
							var $s = $slide;
						}
						
	
					$hero.trigger('to.owl.carousel', $s);
				});
            }
            
            
            
           
            
            const $halfslider_section = $('.halfslider');
            if ($halfslider_section.length > 0) {
                $halfslider_section.owlCarousel({
	                items: 1,
                    dots: false,
                    nav: false,
                    loop: true,
                    autoplay: true,
                    smartSpeed: 550,
                    slideSpeed: 550,
                    autoplayTimeout: 4500,
                    mouseDrag: true,
                    lazyLoad:true,
                    touchDrag: true,         
                });
                Sliders.setDrawing($halfslider_section);
                
            }
            
            const $halfslider_image = $('.halfslider-image');
            if ($halfslider_image.length > 0) {
                $halfslider_image.owlCarousel({
	                items: 1,
                    dots: false,
                    nav: false,
                    loop: true,
                    autoplay: true,
                    smartSpeed: 550,
                    slideSpeed: 550,
                    autoplayTimeout: 4500,
                    mouseDrag: true,
                    lazyLoad:true,
                    touchDrag: true,         
                });
                Sliders.setDrawing($halfslider_image);
            }
            
            const $halfslider_text = $('.halfslider-text');
            if ($halfslider_text.length > 0) {
                $halfslider_text.owlCarousel({
	                items: 1,
                    dots: false,
                    nav: false,
                    loop: true,
                    autoplay: false,
                    smartSpeed: 550,
                    slideSpeed: 550,
                    autoplayTimeout: 4500,
                    mouseDrag: false,
                    lazyLoad:true,
                    touchDrag: false,         
                });
                Sliders.setDrawing($halfslider_text);
            }
            
            $halfslider_image.on('change.owl.carousel', function(event) {
                if (event.namespace && event.property.name === 'position') {
                    var target = event.relatedTarget.relative(event.property.value, true);
                    $halfslider_text.owlCarousel('to', target, 550, true);
                }
            });

            const $regular_flights = $('.flights-slider');
            if ($regular_flights.length > 0) {
                $regular_flights.owlCarousel({
                    nav: true,
                    navText: [`<span>${this.owlArrow}</span>`, `<span>${this.owlSpinningCirce}${this.owlArrow}</span>`],
                    responsive: {
                        0: {
                            items: 2,
                        },
                        768: {
                            items: 3,
                        },
                        992: {
                            items: 4,
                        },
                        1200: {
                            items: 5,
                        }
                    },
                    responsiveClass: true,
                    dots: false,
                    loop: true,
                    autoplay: true,
                    smartSpeed: 550,
                    slideSpeed: 550,
                    autoplayTimeout: 4500,
                    mouseDrag: true,
                    touchDrag: true,
                    lazyLoad:true,
                    autoplayHoverPause: true            
                })
            }

            const $partners_section = $('.partners__slider');
            if ($partners_section.length > 0) {
                $partners_section.owlCarousel({
                    responsive: {
                        0: {
                            items: 3,
                        },
                        768: {
                            items: 4,
                        },
                        992: {
                            items: 5,
                        },
                        1200: {
                            items: 6,
                        }
                    },
                    responsiveClass: true,
                    dots: false,
                    nav: false,
                    loop: true,
                    autoplay: true,
                    smartSpeed: 550,
                    slideSpeed: 550,
                    autoplayTimeout: 4500,
                    mouseDrag: true,
                    lazyLoad:true,
                    touchDrag: true,       
                });
            }
            

            const $gallery_slider = $(".gallery-slider__slides");
            if ($gallery_slider.length > 0) {
                $gallery_slider.owlCarousel({
                    margin: 0,
                    autoplay: true,
                    smartSpeed: 550,
                    slideSpeed: 550,
                    autoplayTimeout: 4500,
                    nav: true,
                    dots: true,
					dotsContainer: ".gallery-slider__thumbnails",
                    items: 1,
                    loop: true,
                    mouseDrag: true,
                    touchDrag: true,
                    navText: [`<span>${this.owlArrow}</span>`, `<span>${this.owlSpinningCirce}${this.owlArrow}</span>`]
                });
            }
        },
        
        startProgressBar: function(owlNameObject) {
	        $(owlNameObject).find(".drawing-circle").css({
              animationName: "circle-chart-fill" ,
            });
            
        },    
		resetProgressBar: function(owlNameObject) {
            $(owlNameObject).find(".drawing-circle").css({
              animationName: "none" ,
            });
        },
		setDrawing: function(owlNameObject) {
	        owlNameObject.on('changed.owl.carousel', function() {
	            owlNameObject.trigger('stop.owl.autoplay');
	            owlNameObject.trigger('play.owl.autoplay');
	        });
			owlNameObject.on('initialized.owl.carousel', function() {
	            owlNameObject.trigger('stop.owl.autoplay');
	            owlNameObject.trigger('play.owl.autoplay');
	            Sliders.startProgressBar(this);
	        });
			owlNameObject.on('play.owl.autoplay', function() {
	            Sliders.startProgressBar(this);
	        });
			owlNameObject.on('translated.owl.carousel', function() {
	            owlNameObject.trigger('stop.owl.autoplay');
	            owlNameObject.trigger('play.owl.autoplay');
	            Sliders.startProgressBar(this);
	        });
			owlNameObject.on('translate.owl.carousel', function() {
	            owlNameObject.trigger('stop.owl.autoplay');
	            owlNameObject.trigger('play.owl.autoplay');
	            Sliders.resetProgressBar(this);
	        });
	    }
    }
    
    const callNow = {
	    init() {
		    const form = document.getElementById("call_now");
		    if (form) {
			    form.addEventListener("submit", this.handleSubmit)
		    }
	    },
        
        
	    handleSubmit(e) {
		    e.preventDefault();
		    
		    const phoneInput = document.getElementById("call-now__phone");
		    const phoneTermsCheckbox = document.getElementById("call-now__terms");
		    const feedbackField = document.querySelector(".call-now__validation");
		    
		    let formData = $(this).serializeArray();
		    formData.push({name: "action",value: "call_now"});
		    
		    if(phoneInput.value === "" || phoneInput.value === null) {
			    feedbackField.textContent = v.validationErrorCallNowEmptyField;
			    feedbackField.classList.add("nonvalid");
                feedbackField.classList.add("show");
                setTimeout(function(){
                    feedbackField.classList.remove("nonvalid");
                    feedbackField.classList.remove("show")
                }, 5000);
                return;
		    }
		    
		    if(phoneInput.value !== "" && phoneTermsCheckbox.checked  || phoneInput.value === null && phoneTermsCheckbox.checked) {
			     callNow.sendRequest(formData);
		    } else {
                feedbackField.textContent = v.validationErrorCallNowTerms;
                feedbackField.classList.add("nonvalid");
                feedbackField.classList.add("show");
                setTimeout(function(){
                    feedbackField.classList.remove("nonvalid");
                    feedbackField.classList.remove("show")
                }, 5000);
                return;
            }

	    },

	    sendRequest(data) {
            const feedbackField = document.querySelector(".call-now__validation");
            $.post(v.ajaxurl, data, function (response){
                if(response.status) {
                    feedbackField.textContent = v.CallNowSuccess;
                    feedbackField.classList.add("success");
                    feedbackField.classList.add("show");
                    setTimeout(function(){
                        feedbackField.classList.remove("success");
                        feedbackField.classList.remove("show");
                    }, 5000);
                    return;
                } else {
                    feedbackField.textContent = v.CallNowSendError;
                    feedbackField.classList.add("nonvalid");
                    feedbackField.classList.add("show");
                    setTimeout(function(){
                        feedbackField.classList.remove("nonvalid");
                        feedbackField.classList.remove("show");
                    }, 5000);
                    return;
                }
            })
        }
    }
    
    const Instagram = {
        init: function () {
            let instagram_slider = $(".instagram-slider");
            if (instagram_slider.length < 1) return;
            var url = "https://api.instagram.com/v1/users/self/media/recent?access_token=6927404798.5a406ee.1c08f66189d1438a8dff805801b7a7ed";
            var count = 10;
            $.ajax({
                url: url, success: function (result) {

                    var data = result.data;
                    data.forEach((insta_post, index) => {
                        if (index < count) {
                            instagram_slider.append("<a href=\" " + insta_post['link'] + "\" target=\"_blank\" ref=\"nofollow\" class=\"instagram-slider__box\"><img src=" + insta_post['images']['low_resolution']['url'] + " \"></a>")
                        }
                    })

                    instagram_slider.owlCarousel({
                        nav: false,
                        loop: true,
                        dots: false,
                        mouseDrag: true,
                        responsiveClass: true,
                        responsive: {
                            0: {
                                items: 2
                            },
                            600: {
                                items: 4
                            },
                            1200: {
                                items: 6
                            }
                        }

                    });
                }
            });

        }
    }

    const Newsletter = {
        init() {
            const form  = document.getElementById("newsletter__form");
            if (form) {
                form.addEventListener("submit", this.handleSubmit)
            }
        },
        vaidateEmail(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        handleSubmit(ev) {
            ev.preventDefault();
            const emailInput = document.getElementById("newsletter__email");
            const termsCheckboxInput = document.getElementById("newsletter__terms");
            const feedbackField = document.querySelector(".newsletter__validation");

            let formData = $(this).serializeArray();
            formData.push({name: "action",value: "newsletter"});

            if (feedbackField.textContent !== "") {
                feedbackField.textContent = "";
            }

            if (emailInput.value === "" || emailInput.value === null) {
                feedbackField.textContent = v.validationErrorNewsletterEmptyField;
                feedbackField.classList.add("nonvalid");
                feedbackField.classList.add("show")
                setTimeout(function(){
                    feedbackField.classList.remove("nonvalid");
                    feedbackField.classList.remove("show")
                }, 5000);
                return;
            } 

            if(Newsletter.vaidateEmail(emailInput.value)) {
                if(termsCheckboxInput.checked) {
                    Newsletter.sendRequest(formData);
                } else {
                    feedbackField.textContent = v.validationErrorNewsletterTermsNotAgreed;
                    feedbackField.classList.add("nonvalid");
                    feedbackField.classList.add("show");
                    setTimeout(function(){
                        feedbackField.classList.remove("nonvalid");
                        feedbackField.classList.remove("show")
                    }, 5000);
                    return;
                }
            } else {
                feedbackField.textContent = v.validationErrorNewsletterEmailField;
                feedbackField.classList.add("nonvalid");
                feedbackField.classList.add("show");
                setTimeout(function(){
                    feedbackField.classList.remove("nonvalid");
                    feedbackField.classList.remove("show")
                }, 5000);
                return;
            }

        
        },
        sendRequest(data) {
            const feedbackField = document.querySelector(".newsletter__validation");
            $.post(v.ajaxurl, data, function (res){
                if(res.status) {
                    feedbackField.textContent = v.newsletterSuccess;
                    feedbackField.classList.add("success");
                    feedbackField.classList.add("show");
                    setTimeout(function(){
                        feedbackField.classList.remove("success");
                        feedbackField.classList.remove("show");
                    }, 5000);
                    return;
                } else {
                    if(res.code == 1304) {
                        feedbackField.textContent = v.newsletterExist;
                        feedbackField.classList.add("success");
                        feedbackField.classList.add("show");
                        setTimeout(function(){
                            feedbackField.classList.remove("success");
                            feedbackField.classList.remove("show");
                        }, 5000);
                        return;
                    } else {
                        feedbackField.textContent = v.newsletterSendError;
                        feedbackField.classList.add("nonvalid");
                        feedbackField.classList.add("show");
                        setTimeout(function(){
                            feedbackField.classList.remove("nonvalid");
                            feedbackField.classList.remove("show");
                        }, 5000);
                        return;
                    }
                }
            })
        }

    }

    const Forms = {
        init() {
/*
            document.querySelectorAll(".form__input").forEach(el => {
                el.addEventListener("focus", this.formAddFocus);
                el.addEventListener("blur", this.formRemoveFocus);
            })
*/
            
            $(document).on("focus", ".form__input", this.formAddFocus);
            $(document).on("blur", ".form__input", this.formRemoveFocus);
                    
            $(document).on("focusin", ".form__checkbox, .form__input--checkbox", this.formAddCheckoutFocus); 
            $(document).on("blur", ".form__checkbox, .form__input--checkbox", this.formRemoveCheckoutFocus);        
                        
/*
            document.querySelectorAll(".form__checkbox, .form__input--checkbox").forEach(el => {
                el.addEventListener("focusin", this.formAddCheckoutFocus);
                el.addEventListener("blur", this.formRemoveCheckoutFocus);
            })
*/

			$(document).on("focus", ".form__input--radio", this.formAddCheckoutFocus);
			$(document).on("focus", ".form__input--radio", this.formRemoveCheckoutFocus);


/*
            document.querySelectorAll(".form__input--radio").forEach(el => {
                el.addEventListener("focus", this.formAddCheckoutFocus);
                el.addEventListener("blur", this.formRemoveCheckoutFocus);
            })
*/

            if (document.documentElement.getAttribute('lang') !== "pl-PL") {
                $.datepicker.regional[ "" ].prevText = "&#x3C;",
                $.datepicker.regional[ "" ].nextText = "&#x3E;",
                $.datepicker.regional[ "" ].firstDay = 1,
                $.datepicker.setDefaults( $.datepicker.regional[ "" ] );
            }

            $(".datepicker").datepicker({
	            dateFormat: "yy-mm-dd", 
                showOtherMonths: true,
                selectOtherMonths: true,
                minDate: 0,
                onSelect: function(e) {
                    $(this).siblings("label").addClass("on-focus")
                }
            })

        },
        formAddFocus(ev) {
            ev.target.parentNode.querySelector("label").classList.add("on-focus");
        },
        formRemoveFocus(ev) {
	        console.log(ev.target.tagName);
            if (ev.target.classList.contains("datepicker") || ev.target.tagName === "SELECT") return;
            if (ev.target.value.trim().length < 1) {
                ev.target.parentNode.querySelector("label").classList.remove("on-focus");
            }
        },
        formAddCheckoutFocus(ev) {
            ev.target.parentNode.classList.add("focused");
        },
        formRemoveCheckoutFocus(ev) {
            ev.target.parentNode.classList.remove("focused");
        }
    }

    const Main = {
        mobileTabsOpen: false,
        langUrl: "",
        init() {
    
            // MegaMenu.init();
            Sliders.init();
            Forms.init();
            callNow.init();
            Instagram.init();
            Newsletter.init();
            new WOW().init();
            
            // if (document.querySelector(".highlighted-info__close") !== null) {
            //     document.querySelector(".highlighted-info__close").addEventListener("click", this.closeHilightedInfo);
            // }
           
            // if(document.querySelector(".searchform__label")) {
            //     document.querySelector(".searchform__label").addEventListener("click", this.handleNavbarSearch)
            // }
            
          
			//video modal
			$(".hero-section__videolink").on("click", function(e) {
				let modalVideoLink = $(this).attr("data-link");
				$("#video-modal").find("iframe").attr("src", modalVideoLink);
			});
			$('#video-modal').on('show.bs.modal', function () {
				//add autoplay
				let videoSrc = $("#video-modal iframe").attr("src");
				$("#video-modal iframe").attr("src", videoSrc+"?autoplay=1");
			});
			$("#video-modal").on('hidden.bs.modal', function (e) {
			  // stop the video
			  $("#video-modal iframe").attr("src", null);
			});
          
			
			$(document).on('click', 'a[href*=#]', function (event) {
				
                if( $(this).attr('href') === "#" || $(this).attr("data-toggle") ) {
                    return;
                } else {
	                let anchor = $( $(this).attr('href').substr( $(this).attr('href').indexOf("#") ) );
	                if(anchor.length > 0) {
		                event.preventDefault();
	                    $('html, body').animate({
	                        scrollTop: anchor.offset().top - $(".header-navbar").height()
	                    }, 1200);
                    }
                }
            });


            $(".navbox-mobile__navigation a").on('click', function(){
                $("body").removeClass("nav-expanded");
                $(".navbox-mobile").removeClass("navbox-mobile--open");
                $(".mobilemenu-toogler__btn .mobilemenu-toogler__display").removeClass("animateNav");
            });


            if (document.querySelector(".mobilemenu-toogler__btn")) {
                document.querySelector(".mobilemenu-toogler__btn").addEventListener("click", this.handleMoblieMenuShow)
            }

            if (document.querySelector(".go-back") !== null) {
                document.querySelector(".go-back").addEventListener("click", function(ev){
                    ev.preventDefault();
                    window.history.back();
                })
            }
            document.querySelectorAll(".play-button").forEach(el => {
                el.addEventListener("click", this.handlePlayVideo);
            })
            document.querySelectorAll(".section-video__video_autoplay").forEach(vid => {
                this.handleAutoPlay(vid)
            })
            
             //navbar animation
            $(window).scroll(function() {
                var scroll = $(window).scrollTop();
                if (scroll >= 50) {
                    $(".header-navbar").addClass("scrolled");
                } else {
                    $(".header-navbar").removeClass("scrolled");
                }
            });
            
            $(".cb_select_car").on("click", this.handleCarSelect);
            
            
            // $(".collapse__item-list").on("show.bs.collapse", function() {
            //     $(".collapse__item").removeClass("active");
            //     $(this).parent().addClass("active");
            // })

            //document.getElementById("changeFontSize").addEventListener("click", this.handleAccessibility)
            //document.getElementById("changeContrast").addEventListener("click", this.handleAccessibility)

            //Forms.init();
            //Newsletter.init();  
        },
        handleMoblieMenuShow(ev) {
/*
            this.classList.toggle("active")
            document.querySelector(".header").classList.add("header--white");
            document.querySelector(".megamenu-mobile").classList.toggle("show");
*/
            document.body.classList.toggle("nav-expanded");
            document.querySelector(".navbox-mobile").classList.toggle("navbox-mobile--open");
            document.querySelector(".mobilemenu-toogler__btn .mobilemenu-toogler__display").classList.toggle("animateNav");
        },
        handleAccessibility() {
            if (this.id === "changeFontSize") {
                if (document.documentElement.style.fontSize === "" || document.documentElement.style.fontSize === null) {
                    document.documentElement.style.fontSize = "1.5rem";
                    document.body.classList.add("bigger-font");
                    document.cookie = `fontsize=3;expires=${(new Date().getTime() + (7*24*60*60*1000)).toUTCString()};path=/`
                } else if (document.documentElement.style.fontSize === "1.5rem") {
                    document.documentElement.style.fontSize = "0.75rem";
                    document.body.classList.add("smaller-font");
                    document.body.classList.remove("bigger-font");
                    document.cookie = `fontsize=2;expires=${(new Date().getTime() + (7*24*60*60*1000)).toUTCString()};path=/`
                } else {
                    document.documentElement.style.fontSize = "";
                    document.body.classList.remove("bigger-font");
                    document.body.classList.remove("smaller-font");
                    document.cookie = `fontsize=2;expires=${(new Date().getTime() + (7*24*60*60*1000)).toUTCString()};path=/`
                }
            }
            if (this.id === "changeContrast") {
                document.body.classList.toggle("high-contrast");
                if (document.body.classList.contains("high-contrast")) {
                    document.cookie = `contrast=1;expires=${(new Date().getTime() + (7*24*60*60*1000)).toUTCString()};path=/`
                } else {
                    document.cookie = `contrast=0;expires=${(new Date().getTime() + (7*24*60*60*1000)).toUTCString()};path=/`
                }
            }
        },
        handleNavbarSearch() {
            this.nextElementSibling.classList.toggle("open");
        },
        handleTabsChange(ev) {
            var link = this.getAttribute("data-target")

            if(window.innerWidth < 768) {
                if(!Main.mobileTabsOpen) {
                    $(".tabs__content").slideDown();
                    document.querySelector(".flightsbox__tabs").classList.toggle("slided-down");
                    Main.mobileTabsOpen = true;
                } else if(document.querySelector(link).classList.contains("tabs__tablewarp--active")) {
                    $(".tabs__content").slideUp();
                    Main.mobileTabsOpen = false;
                    document.querySelector(".flightsbox__tabs").classList.toggle("slided-down");
                    document.querySelectorAll(".tabs__btn").forEach(el => el.classList.remove('tabs__btn--active'));
                    return;
                }
            }

            document.querySelectorAll(".tabs__btn").forEach(el => el.classList.remove('tabs__btn--active'));
            this.classList.add('tabs__btn--active');
            
            document.querySelectorAll(".tabs__tablewarp").forEach(el => el.classList.remove('tabs__tablewarp--active'));
            // document.querySelectorAll(link).fadeIn();
            document.querySelector(link).classList.add("tabs__tablewarp--active");
            
        },
        handlePlayVideo(ev) {
            const parent = ev.target.parentNode;
            const video = parent.querySelector("video");
            for(let i = 0; i < parent.children.length; i++) {
                const el = parent.children[i];
                if (el === video  || el.classList.contains("nohide")) continue;
                el.classList.add("hide");
            }
            video.classList.add("played");
            video.play();
            
            video.addEventListener("ended", () => {
	            video.classList.remove("played");
	            for(let i = 0; i < parent.children.length; i++) {
	                const el = parent.children[i];
	                if (el === video) continue;
	                el.classList.remove("hide");
	            }
            })
        },
        handleAutoPlay (video) {
			var promise = video.play();
			if (promise !== undefined) {
				promise.then(function () {
// 					video.classList.add("played")			
				}).catch(function (error) {
					// Autoplay was prevented.
					video.classList.remove("autoplay");
					$(".play-button").show();
				});
			}
        },
        handleCarSelect(ev) {
	        // not preventing, smooth scroll to form section
	        var car = this.getAttribute("data-car");
	        if ( !car || $("option[value='"+car+"']").length < 1 ) return;

	        
	        var selectors = $("select[name='category_id']");
	        selectors.each((idx) => {
		        selectors[idx].value = car;
	        }) 
	        
        }
    }


    Main.init();
})(jQuery);